import React from "react"
import H3 from "../H3"
import P from "../P"
import { FlagIcon } from "react-flag-kit";

export const GetInTouch = () => {
  return (
    <>
      <H3 title="Other ways to get in touch with us!" />
      <div className="grid grid-cols-12 lg:grid-cols-12 mt-6 gap-6">
        <div className="bg-white flex-col flex px-4 md:col-span-6 lg:col-span-12 col-span-12 py-4 shadow-lg rounded-lg">
          <P title=" Project Request" noPad={true} noCenter={true} className=" font-semibold" />
          <P title="Mail us your project requirement and get the free quote" textSize="text-sm" className='text-shark-400 mt-1' noPad={true} noCenter={true} />
          <a href="mailto:sales@webmobtech.com">
            <P
              noPad
              title={`sales@webmobtech.com`}
              className="font-medium text-left text-blue-500 mt-3"
              textSize="text-lg"
            />
          </a>
          <div className="flex items-center">
            <span className="text-shark-500 font-sans inline-flex items-center gap-x-1.5" role="img" aria-label="IND" ><FlagIcon code={"IN"} style={{ width: '16px', height: '13px' }} /><span>IND:</span><a href="tel:+91-70-43866892">
              <p className="font-sans text-blue-500 font-medium inline-block" dangerouslySetInnerHTML={{ __html: " +91-70-43866892" }}>
              </p>
            </a>
            </span>
            <div>
              <a target="_blank" rel="noreferrer" href="https://wa.me/917043866892?text=Hello%20%2C%20I%20have%20a%20questions%20about%20your%20services.%20Can%20you%20please%20help%20me%3F">
                <div className="h-5 w-5 ml-3 mt-1 rounded-full inline-block bg-green-500 justify-center items-center"><img src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/whatsapp.png" className="w-full h-auto" alt="Whatsapp" /></div>
              </a>
            </div>
          </div>
        </div>
        <div className="bg-white flex-col flex px-4 py-4 md:col-span-6 lg:col-span-12 col-span-12 shadow-lg rounded-lg">
          <P title="Apply for Job" noPad={true} noCenter={true} className=" font-semibold" />
          <P title="We're looking for problem solver. Come and join our team" textSize="text-sm" className='text-shark-400 mt-1' noPad={true} noCenter={true} />
          <a href="mailto:career@webmobtech.com">
            <P
              noPad
              title={`career@webmobtech.com`}
              className="font-medium text-left text-blue-500 mt-3"
              textSize="text-lg"
            />
          </a>
          <div className="flex items-center">
            <span className="text-shark-500 font-sans inline-flex items-center gap-x-1.5" role="img" aria-label="IND" ><FlagIcon code={"IN"} style={{ width: '16px', height: '13px' }} /><span>IND:</span><a href="tel:+91-79-49190827">
              <p className="font-sans text-blue-500 font-medium inline-block" dangerouslySetInnerHTML={{ __html: " +91-79-49190827" }}>
              </p>
            </a>
            </span>
            <div>
              <a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send/?phone=%2B917949190827">
                <div className="h-5 w-5 ml-3 mt-1 rounded-full inline-block bg-green-500 justify-center items-center"><img src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/04/whatsapp.png" className="w-full h-auto" alt="Whatsapp" /></div>
              </a>
            </div>
          </div>
        </div>
        <div className="bg-white flex-col flex px-4 py-4 shadow-lg rounded-lg col-span-12">
          <P title="Contact Us" noPad={true} noCenter={true} className=" font-semibold" />
          <P title="Let's have a chat! Call us at" textSize="text-sm" className='text-shark-400 mt-1' noPad={true} noCenter={true} />

          <div className="mt-4 flex flex-col">
            <span className="text-shark-500 font-sans inline-flex items-center gap-x-1.5" role="img" aria-label="USA"><FlagIcon code={"US"} style={{ width: '16px', height: '13px' }} /><span>USA:</span><a href="tel:+1-408-520-9597">
              <p className="font-sans text-blue-500 font-medium pr-4 inline-block" dangerouslySetInnerHTML={{ __html: "+1-408-520-9597" }}>
              </p>
            </a>
            </span>
            <span className="text-shark-500 font-sans inline-flex items-center gap-x-1.5" role="img" aria-label="IND" ><FlagIcon code={"IN"} style={{ width: '16px', height: '13px' }} /><span>IND:</span><a href="tel:+91-70-43866892">
              <p className="font-sans text-blue-500 font-medium inline-block" dangerouslySetInnerHTML={{ __html: " +91-70-43866892" }}>
              </p>
            </a>
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default GetInTouch
